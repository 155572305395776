import { t } from "@lingui/macro"
import { BigNumber, ethers } from "ethers"
import { useSnackbar } from "notistack"
import { useCallback, useEffect, useState } from "react"

import { useLootbox } from "../../../../../../contexts/LootboxContext"
import { useWeb3Connection } from "../../../../../../contexts/Web3ConnectionContext"
import ERC20Abi from "../../../../../../contracts/Generic/GenericERC20.json"
import { truncateDecimals } from "../../../../../../utils/Helpers"
import type { ILootboxReward, RewardUnitsHook } from "../types"
import { TOKEN_TYPE_KEYS, type LootboxInventoryResult } from "../../../../../../types/lootbox"

export const useRewardUnits = (): RewardUnitsHook => {
  const { network, signer } = useWeb3Connection()
  const { lootboxInventory, updateRewards } = useLootbox()

  const [lootboxRewards, setLootboxRewards] = useState<ILootboxReward[]>([])
  const [isRewardsProcessing, setIsRewardsProcessing] = useState(true)
  const [isRewardsLoading, setIsRewardsLoading] = useState(false)
  const [hasRewardsChanged, setHasRewardsChanged] = useState(false)
  const [isVarERC20ModalOpen, setIsVarERC20ModalOpen] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const processERC20Reward = useCallback(
    async (
      reward: LootboxInventoryResult,
      leftOverResult?: LootboxInventoryResult
    ): Promise<ILootboxReward[]> => {
      const erc20Contract = new ethers.Contract(reward.rewardToken, ERC20Abi, signer)
      let decimals
      try {
        decimals = await erc20Contract.decimals()
      } catch (err) {
        decimals = 18
      }
      const amountPerUnit = parseFloat(
        truncateDecimals(ethers.utils.formatUnits(reward.amountPerUnit.toString(), decimals), 2)
      )
      let balance = parseFloat(
        truncateDecimals(ethers.utils.formatUnits(reward.balance.toString(), decimals), 1)
      )

      if (leftOverResult) {
        balance += parseFloat(
          truncateDecimals(ethers.utils.formatUnits(leftOverResult.balance.toString(), decimals), 1)
        )
      }

      return [
        {
          tokenAddress: reward.rewardToken,
          rewardType: reward.rewardType,
          amountPerUnit: amountPerUnit.toString(),
          remainingBalance: balance,
          decimals: decimals,
        },
      ]
    },
    [signer]
  )

  const processERC1155Reward = useCallback((reward: LootboxInventoryResult): ILootboxReward[] => {
    return reward.extra
      .filter((itemExtra) => itemExtra.id !== undefined)
      .map((itemExtra) => ({
        tokenAddress: reward.rewardToken,
        rewardType: reward.rewardType,
        remainingBalance: itemExtra.balance.toNumber(),
        amountPerUnit: itemExtra.amountPerUnit.toString(),
        tokenId: itemExtra.id?.toString(),
      }))
  }, [])

  const processLootboxInventory = useCallback(async (): Promise<ILootboxReward[]> => {
    const rewards: ILootboxReward[] = []

    const allRewards = [
      ...(lootboxInventory?.result || []),
      ...(lootboxInventory?.leftoversResult?.filter(
        (leftoverResult) =>
          !lootboxInventory.result?.some(
            (result) => result.rewardToken === leftoverResult.rewardToken
          )
      ) || []),
    ]

    for (const reward of allRewards) {
      const leftOverResult = lootboxInventory?.leftoversResult?.find(
        (leftover) => leftover.rewardToken === reward.rewardToken
      )

      if (TOKEN_TYPE_KEYS.ERC20.some((tokenType) => tokenType === reward.rewardType)) {
        // ERC20 processing
        const processedERC20Reward = await processERC20Reward(reward, leftOverResult)
        rewards.push(...processedERC20Reward)
      } else if (
        TOKEN_TYPE_KEYS.ERC721.some((tokenType) => tokenType === reward.rewardType) ||
        TOKEN_TYPE_KEYS.ERC1155NFT.some((tokenType) => tokenType === reward.rewardType)
      ) {
        // ERC721 and ERC1155NFT processing
        rewards.push({
          tokenAddress: reward.rewardToken,
          rewardType: reward.rewardType,
          amountPerUnit: reward.amountPerUnit.toString(),
          remainingBalance: reward.extra?.length || 0,
        })
      } else {
        // ERC1155 processing
        const processed1155Rewards = processERC1155Reward(reward)
        rewards.push(...processed1155Rewards)
      }
    }

    setLootboxRewards(rewards)

    return rewards
  }, [lootboxInventory, processERC20Reward, processERC1155Reward])

  useEffect(() => {
    setIsRewardsProcessing(true)
    processLootboxInventory()
      .catch(console.error)
      .finally(() => {
        setIsRewardsProcessing(false)
      })
  }, [processLootboxInventory])

  const updateLootboxRewards = useCallback(async () => {
    if (!hasRewardsChanged) return

    // validate amounts per unit
    if (
      lootboxRewards.some((reward) => {
        const amount = TOKEN_TYPE_KEYS.ERC20.some((tokenType) => tokenType === reward.rewardType)
          ? parseFloat(reward.amountPerUnit)
          : parseInt(reward.amountPerUnit)
        return Number.isNaN(amount) || amount < 0
      })
    ) {
      enqueueSnackbar("Number of rewards must be valid", {
        variant: "error",
        autoHideDuration: 5000,
      })
      return
    }

    // validate amounts per unit must be 1 or 0 for 721 and 1155NFT
    if (
      lootboxRewards.some(
        (reward) =>
          [...TOKEN_TYPE_KEYS.ERC721, ...TOKEN_TYPE_KEYS.ERC1155NFT].some(
            (tokenType) => tokenType === reward.rewardType
          ) && parseInt(reward.amountPerUnit) > 1
      )
    ) {
      enqueueSnackbar("Number of rewards for ERC721 and ERC1155NFT cannot be more than 1", {
        variant: "error",
        autoHideDuration: 5000,
      })
      return
    }

    const amountsPerUnit = lootboxRewards.map((reward) =>
      TOKEN_TYPE_KEYS.ERC20.some((tokenType) => tokenType === reward.rewardType)
        ? ethers.utils.parseUnits(reward.amountPerUnit, reward.decimals ?? 18)
        : BigNumber.from(parseInt(reward.amountPerUnit))
    )

    setIsRewardsLoading(true)

    try {
      await updateRewards(
        lootboxRewards.map((reward) => reward.tokenAddress),
        lootboxRewards.map((reward) => reward.tokenId ?? "0"),
        amountsPerUnit
      )

      enqueueSnackbar(t`LootBox rewards have been updated`, { variant: "success" })
      setHasRewardsChanged(false)
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" })
    } finally {
      setIsRewardsLoading(false)
    }
  }, [hasRewardsChanged, updateRewards, lootboxRewards, enqueueSnackbar])

  return {
    network,
    isRewardsLoading,
    isRewardsProcessing,
    setIsRewardsLoading,
    updateLootboxRewards,
    lootboxRewards,
    setLootboxRewards,
    hasRewardsChanged,
    setHasRewardsChanged,
    isVarERC20ModalOpen,
    setIsVarERC20ModalOpen,
  }
}
