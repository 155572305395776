import { Trans, t } from "@lingui/macro"
import { TextField, Typography } from "@mui/material"

import { CrossCircleIcon } from "../../../../assets/icons/CrossCircleIcon"
import { SoftButton } from "../../../elements/SoftButton"

import ManageLootboxHeader from "./ManageHeader"
import { useMintAndTransfer } from "./hooks/useMintAndTransfer"
import {
  AddAddressText,
  CrossActionBox,
  MintAndTransferActionBox,
  MintAndTransferBody,
  MintAndTransferContainer,
  MintAndTransferHeader,
  NoPayerText,
  NumberOfLootboxesBox,
  NumberOfRewardsBox,
  PlayerActionBox,
  PlayerAddressBox,
  PlayerAddressInputBox,
  PlayerTransferRow,
  RewardsInput,
  RewardsInputBox,
} from "./styles/MintAndTransfer.styles"

const MintAndTransfer = (): JSX.Element => {
  const {
    playerMints,
    isMintsLoading,
    playerMintsErrors,
    mintAndTransfer,
    addMintAndTransferData,
    updateNumberOfLootboxes,
    updateNumberOfRewards,
    updatePlayerAddress,
    removeMintAndTransferData,
  } = useMintAndTransfer()

  return (
    <MintAndTransferContainer>
      <ManageLootboxHeader
        heading={t`LootBox minting`}
        subtitle={t`Mint and transfer your LootBox directly to players. First enter the address of the player you wish to send the LootBox to, then specify the quantity of LootBoxes in this transaction.`}
      />
      <MintAndTransferBody>
        <MintAndTransferHeader>
          <PlayerAddressBox>
            <Typography>
              <Trans>Player address</Trans>
            </Typography>
          </PlayerAddressBox>
          <NumberOfRewardsBox>
            <Typography align="center">
              <Trans># of rewards</Trans>
            </Typography>
          </NumberOfRewardsBox>
          <NumberOfLootboxesBox>
            <Typography align="center">
              <Trans># of LootBoxes</Trans>
            </Typography>
          </NumberOfLootboxesBox>
          <PlayerActionBox />
        </MintAndTransferHeader>
        {playerMints?.length ? (
          playerMints.map((playerMint, i) => (
            <PlayerTransferRow key={playerMint.id}>
              <PlayerAddressInputBox>
                <TextField
                  size="small"
                  label={t`Token address ${i + 1}`}
                  value={playerMint.playerAddress}
                  onChange={(e) => updatePlayerAddress(playerMint.id, e.target.value)}
                  placeholder="0x..."
                  error={!!playerMintsErrors[i]}
                  helperText={playerMintsErrors[i]}
                  fullWidth
                />
              </PlayerAddressInputBox>

              {/* # of rewards */}
              <RewardsInputBox>
                <RewardsInput
                  value={playerMint.numberOfRewards}
                  size="small"
                  onChange={(e) => updateNumberOfRewards(playerMint.id, e.target.value)}
                />
              </RewardsInputBox>

              {/* # of lootboxes */}
              <RewardsInputBox>
                <RewardsInput
                  value={playerMint.numberOfLootboxes}
                  size="small"
                  onChange={(e) => updateNumberOfLootboxes(playerMint.id, e.target.value)}
                />
              </RewardsInputBox>

              {/* cross */}
              <PlayerActionBox>
                <CrossActionBox onClick={() => removeMintAndTransferData(playerMint.id)}>
                  <CrossCircleIcon />
                </CrossActionBox>
              </PlayerActionBox>
            </PlayerTransferRow>
          ))
        ) : (
          <NoPayerText>
            <Trans>No Player addresses added</Trans>
          </NoPayerText>
        )}
        <AddAddressText onClick={addMintAndTransferData}>
          <Trans>Add another address</Trans>
        </AddAddressText>
      </MintAndTransferBody>
      <MintAndTransferActionBox>
        <SoftButton onClick={mintAndTransfer} loading={isMintsLoading}>
          <Trans>Mint and transfer</Trans>
        </SoftButton>
      </MintAndTransferActionBox>
    </MintAndTransferContainer>
  )
}

export default MintAndTransfer
