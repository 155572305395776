import { useLocalStorage } from "@chainsafe/browser-storage-hooks"
import { Trans, t } from "@lingui/macro"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import {
  Box,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemButton,
  useTheme,
  Typography,
  Popover,
  Button,
} from "@mui/material"
import ListItemText from "@mui/material/ListItemText"
import { useConnectWallet } from "@web3-onboard/react"
import type { ReactNode, MouseEvent } from "react"
import React, { useState, useCallback, useMemo } from "react"
import { useNavigate, useLocation } from "react-router-dom"

import { AnalyticsIcon } from "../../assets/icons/SidebarIcons/AnalyticsIcon"
import { LootboxesIcon } from "../../assets/icons/SidebarIcons/LootboxesIcon"
import { MarketplaceIcon } from "../../assets/icons/SidebarIcons/MarketplaceIcon"
import { MyProjectsIcon } from "../../assets/icons/SidebarIcons/MyProjectsIcon"
import { NFTsLaunchpadIcon } from "../../assets/icons/SidebarIcons/NFTsLaunchpadIcon"
import { RPCNodesIcon } from "../../assets/icons/SidebarIcons/RPCNodesIcon"
import { SettingsIcon } from "../../assets/icons/SidebarIcons/SettingsIcon"
import { SignoutIcon } from "../../assets/icons/SidebarIcons/SignoutIcon"
import { useCountly } from "../../contexts/CountlyContext"
import { useMarketplace } from "../../contexts/MarketplaceContext"
import { useNFTs } from "../../contexts/NFTsContext"
import { useStorageApi } from "../../contexts/StorageContext"
import { useWeb3Connection } from "../../contexts/Web3ConnectionContext"
import RouteTracker from "../../routes/RouteTracker"
import { ROUTE_LINKS } from "../../routes/routes"
import { DRAWER_WIDTH } from "../../themes/theme"
import { centerEllipsis } from "../../utils/Helpers"
import { TopBanner } from "../elements/Banners/TopBanner"

const bannerKey = "csgd.bannerKey"
const currentBanner = "enter-to-win"

export const DashboardLayout = ({ children }: { children: ReactNode }): JSX.Element => {
  const [{ wallet }, connect, disconnect] = useConnectWallet()
  const { network, address, isLoggedIn } = useWeb3Connection()
  const { NFTsProject } = useNFTs()
  const { marketplaceProject } = useMarketplace()
  const { localStorageSet } = useLocalStorage()
  const { trackEvent } = useCountly()

  const [showBanner, setShowBanner] = useState(
    // will be reused when we have a banner
    // canUseLocalStorage && localStorageGet(bannerKey) !== currentBanner
    false
  )

  // useEffect(() => {
  //   if (canUseLocalStorage && localStorageGet(bannerKey) !== currentBanner) {
  //     setShowBanner(true)
  //   }
  // }, [canUseLocalStorage, localStorageGet])

  const { logout } = useStorageApi()
  const theme = useTheme()

  const navigate = useNavigate()
  const { pathname: path } = useLocation()

  const [walletAnchorEl, setWalletAnchorEl] = useState<HTMLElement | null>(null)

  const handleWalletClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setWalletAnchorEl(event.currentTarget)
  }, [])

  const handleWalletClose = useCallback(() => {
    setWalletAnchorEl(null)
  }, [])

  const onTopBannerClose = useCallback(() => {
    setShowBanner(false)
    localStorageSet(bannerKey, currentBanner)
  }, [localStorageSet])

  const [open, id] = useMemo(
    () => [!!walletAnchorEl, walletAnchorEl ? "wallet-popover" : undefined],
    [walletAnchorEl]
  )

  return (
    <>
      <RouteTracker />
      <Box>
        {showBanner ? <TopBanner onClose={onTopBannerClose} /> : null}
        <Box display="flex">
          <Drawer
            sx={{
              mt: 6,
              width: DRAWER_WIDTH,
              backgroundColor: "#0F0F0F",
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: DRAWER_WIDTH,
                boxSizing: "border-box",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <Box mt={showBanner ? 11 : 5} mb={4} ml={2}>
              <img
                src="/chainsafe-logo-svg.svg"
                style={{ cursor: "pointer", width: "48px", height: "48px" }}
                onClick={() => navigate(ROUTE_LINKS.Dashboard)}
              />
            </Box>
            <Divider />
            <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
              <List>
                <ListItem
                  key="projects"
                  disablePadding
                  onClick={() => {
                    navigate(ROUTE_LINKS.Dashboard)
                  }}
                >
                  <ListItemButton>
                    <MyProjectsIcon
                      fill={
                        path.includes(ROUTE_LINKS.Dashboard)
                          ? theme.palette.primary.main
                          : undefined
                      }
                      sx={{ mr: 1.5 }}
                    />
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: 500,
                        color: path.includes(ROUTE_LINKS.Dashboard)
                          ? undefined
                          : theme.palette.grey[400],
                      }}
                      data-cy="button-left-panel-my-projects"
                      primary={t`My Projects`}
                    />
                  </ListItemButton>
                </ListItem>

                <ListItem
                  key="analytics"
                  disablePadding
                  onClick={() => {
                    navigate(ROUTE_LINKS.Analytics)
                  }}
                >
                  <ListItemButton>
                    <AnalyticsIcon
                      fill={
                        path.includes(ROUTE_LINKS.Analytics)
                          ? theme.palette.primary.main
                          : undefined
                      }
                      sx={{ mr: 1.5 }}
                    />
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: 500,
                        color: path.includes(ROUTE_LINKS.Analytics)
                          ? undefined
                          : theme.palette.grey[400],
                      }}
                      data-cy="button-left-panel-analytics"
                      primary={t`Analytics`}
                    />
                  </ListItemButton>
                </ListItem>

                <ListItem
                  key={"nodes"}
                  disablePadding
                  onClick={() => {
                    navigate(ROUTE_LINKS.RPCNodes)
                  }}
                >
                  <ListItemButton>
                    <RPCNodesIcon
                      fill={
                        path.includes(ROUTE_LINKS.RPCNodes) ? theme.palette.primary.main : undefined
                      }
                      sx={{ mr: 1.7 }}
                    />
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: 500,
                        color: path.includes(ROUTE_LINKS.RPCNodes)
                          ? undefined
                          : theme.palette.grey[400],
                      }}
                      data-cy="button-left-panel-rpc-nodes"
                      primary={t`RPC Nodes`}
                    />
                  </ListItemButton>
                </ListItem>

                <ListItem
                  key="nfts"
                  disablePadding
                  onClick={() => {
                    if (NFTsProject?.projectId)
                      navigate(ROUTE_LINKS.NFTsOnProject(NFTsProject.projectId))
                    else navigate(ROUTE_LINKS.NFTs)
                  }}
                >
                  <ListItemButton>
                    <NFTsLaunchpadIcon
                      fill={
                        path.includes(ROUTE_LINKS.NFTs) ? theme.palette.primary.main : undefined
                      }
                      sx={{ mr: 1.5 }}
                    />
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: 500,
                        color: path.includes(ROUTE_LINKS.NFTs)
                          ? undefined
                          : theme.palette.grey[400],
                      }}
                      data-cy="button-left-panel-nft-launchpad"
                      primary={t`NFT Launchpad`}
                    />
                  </ListItemButton>
                </ListItem>

                <ListItem
                  key="marketplace"
                  disablePadding
                  onClick={() => {
                    if (marketplaceProject?.projectId)
                      navigate(ROUTE_LINKS.marketplacesOnProject(marketplaceProject.projectId))
                    else navigate(ROUTE_LINKS.marketplaces)
                  }}
                >
                  <ListItemButton>
                    <MarketplaceIcon
                      fill={
                        path.includes(ROUTE_LINKS.marketplaces)
                          ? theme.palette.primary.main
                          : undefined
                      }
                      sx={{ mr: 1.5 }}
                    />
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: 500,
                        color: path.includes(ROUTE_LINKS.marketplaces)
                          ? undefined
                          : theme.palette.grey[400],
                      }}
                      data-cy="button-left-panel-marketplaces"
                      primary={t`Marketplaces`}
                    />
                  </ListItemButton>
                </ListItem>

                <ListItem
                  key="lootboxes"
                  disablePadding
                  onClick={() => {
                    navigate(ROUTE_LINKS.Lootboxes)
                  }}
                >
                  <ListItemButton>
                    <LootboxesIcon
                      fill={
                        path.includes(ROUTE_LINKS.Lootboxes)
                          ? theme.palette.primary.main
                          : undefined
                      }
                      sx={{ mr: 1.5 }}
                    />
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: 500,
                        color: path.includes(ROUTE_LINKS.Lootboxes)
                          ? undefined
                          : theme.palette.grey[400],
                      }}
                      data-cy="button-left-panel-lootboxes"
                      primary={t`LootBoxes`}
                    />
                  </ListItemButton>
                </ListItem>

                <ListItem
                  key={"settings"}
                  disablePadding
                  onClick={() => {
                    navigate(ROUTE_LINKS.Settings)
                  }}
                >
                  <ListItemButton>
                    <SettingsIcon
                      fill={
                        path.includes(ROUTE_LINKS.Settings) ? theme.palette.primary.main : undefined
                      }
                      sx={{ mr: 1.7 }}
                    />
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: 500,
                        color: path.includes(ROUTE_LINKS.Settings)
                          ? undefined
                          : theme.palette.grey[400],
                      }}
                      data-cy="button-left-panel-settings"
                      primary={t`Settings`}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
              <List sx={{ mb: 2 }}>
                <ListItem key={"signOut"} disablePadding onClick={logout}>
                  <ListItemButton>
                    <SignoutIcon sx={{ mr: 1.5, mt: 0.35 }} />
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: 500,
                        color: theme.palette.grey[400],
                      }}
                      data-cy="button-left-panel-logout"
                      primary={t`Sign out`}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Drawer>
          <Box display="flex" alignItems="center" flexDirection="column" mt={5} width="100%">
            <Box minHeight={"calc(100vh - 100px)"} width="100%" pr={6} pl={6}>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                gap={2.5}
                mb={4}
                height="36.5px"
              >
                <a
                  href={ROUTE_LINKS.Discord}
                  target="_blank"
                  onClick={() => {
                    trackEvent({ key: "discord-support-click" })
                  }}
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", backgroundColor: "transparent" }}
                  data-cy="button-discord-support"
                >
                  <Box
                    sx={{
                      borderRadius: "12px",
                      backgroundColor: "rgba(20, 20, 20, 0.4)",
                      p: "6px 12px",
                    }}
                  >
                    <Typography>
                      <Trans>Discord Support</Trans>
                    </Typography>
                  </Box>
                </a>
                <a
                  target="_blank"
                  href={
                    path.includes(ROUTE_LINKS.NFTs)
                      ? ROUTE_LINKS.DocsNFTLaunchpad
                      : path.includes(ROUTE_LINKS.marketplaces)
                        ? ROUTE_LINKS.DocsMarketplace
                        : ROUTE_LINKS.Docs
                  }
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackEvent({ key: "docs-click" })
                  }}
                  style={{ textDecoration: "none", backgroundColor: "transparent" }}
                  data-cy="button-docs"
                >
                  <Box
                    sx={{
                      borderRadius: "12px",
                      backgroundColor: "rgba(20, 20, 20, 0.4)",
                      p: "6px 12px",
                    }}
                  >
                    <Typography>
                      <Trans>Docs</Trans>
                    </Typography>
                  </Box>
                </a>

                {isLoggedIn && address ? (
                  <>
                    <Box
                      aria-describedby={id}
                      display="flex"
                      sx={{
                        cursor: "pointer",
                        borderRadius: "12px",
                        border: "1px solid #595959",
                        background: "#333333",
                        pt: 0.75,
                        pb: 0.75,
                        pl: 2,
                        pr: 2,
                      }}
                      gap={1}
                      onClick={handleWalletClick}
                      data-cy="dropdown-wallet-information"
                    >
                      <Typography data-cy="label-wallet-address">
                        {centerEllipsis(address)}
                      </Typography>
                      <Typography
                        sx={{
                          ml: 0.5,
                          color: network ? "#DFF7C7" : "#FCA5A5",
                          background: "#595959",
                          borderRadius: "12px",
                          fontSize: "15px",
                          pr: 1.5,
                          pl: 1.5,
                        }}
                        data-cy="label-wallet-network"
                      >
                        {network?.label || "Unsupported"}
                      </Typography>
                      <KeyboardArrowDownIcon />
                    </Box>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => connect()}
                    sx={{
                      color: "#F5F5F5",
                      borderColor: "#595959",
                      background: "#333333",
                      ":hover": {
                        color: "#F5F5F5",
                        borderColor: "#595959",
                        background: "#333333",
                      },
                    }}
                    data-cy="button-top-bar-connect-wallet"
                  >
                    <Trans>Connect wallet</Trans>
                  </Button>
                )}
              </Box>
              <Popover
                id={id}
                open={open}
                anchorEl={walletAnchorEl}
                onClose={handleWalletClose}
                anchorOrigin={{
                  vertical: 48,
                  horizontal: "left",
                }}
              >
                <Box
                  p={2}
                  pl={3}
                  pr={3}
                  borderRadius="8px"
                  display="flex"
                  alignItems="flex-start"
                  sx={{ bgcolor: "#333333" }}
                >
                  <Box pr={4}>
                    <Box>
                      <Typography fontSize={14}>Wallet address</Typography>
                      <Typography variant="body2" color="#8C8C8C">
                        {centerEllipsis(address || "", 9)}
                      </Typography>
                    </Box>
                    <Box mt={1}>
                      <Typography fontSize={14}>Network</Typography>
                      <Typography
                        sx={{
                          color: network ? "#DFF7C7" : "#FCA5A5",
                          fontSize: "14px",
                          width: "fit-content",
                        }}
                      >
                        {network?.label || "Unsupported network"}
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    sx={{
                      pl: 2,
                      pr: 2,
                      border: "1px solid #595959",
                    }}
                    onClick={() => {
                      handleWalletClose()
                      if (isLoggedIn && wallet) disconnect(wallet)
                      else connect()
                    }}
                    data-cy="button-wallet-disconnect"
                  >
                    {isLoggedIn ? <Trans>Disconnect</Trans> : <Trans>Connect</Trans>}
                  </Button>
                </Box>
              </Popover>
              {children}
            </Box>
            {/* <Footer /> */}
          </Box>
        </Box>
      </Box>
    </>
  )
}
