import { Trans } from "@lingui/macro"
import { ContentCopy } from "@mui/icons-material"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import { Box, Button, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import React from "react"
import { useNavigate } from "react-router-dom"

import { ROUTE_LINKS } from "../../../routes/routes"
import { centerEllipsis, getIPFSUri } from "../../../utils/Helpers"
import { SoftButton } from "../../elements/SoftButton"
import type { LootboxData } from "../../../types/lootbox"

interface ILootboxCard {
  serial: number
  lootbox: LootboxData
}

const LootBoxCard: React.FC<ILootboxCard> = ({ serial, lootbox: { name, address, image } }) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        background: "#141414",
        borderRadius: "5px",
        border: "1px solid #262626",
        height: 100,
      }}
    >
      {image && (
        <Box display="flex">
          <img
            src={getIPFSUri(image)}
            style={{ height: "100px", width: "160px", objectFit: "cover", borderRadius: "8px" }}
          />
        </Box>
      )}
      <Box flex={1} pl={3}>
        <Typography fontSize={20} fontWeight={500} mb={0.5}>
          {name || `Lootbox ${serial}`}
        </Typography>
        <Box display="flex" gap={1} alignItems="center">
          <Typography variant="body2" color="#8C8C8C">
            {centerEllipsis(address, 14)}
          </Typography>
          <ContentCopy
            color="primary"
            sx={{ cursor: "pointer", fontSize: 14 }}
            onClick={() => {
              enqueueSnackbar("Lootbox address copied", { variant: "success" })
              navigator.clipboard.writeText(address)
            }}
          />
        </Box>
      </Box>
      <Box display="flex" gap={1} flex={1} justifyContent="flex-end" pr={3}>
        <SoftButton
          sx={{ pl: 2, pr: 2, color: "white" }}
          onClick={() => {
            navigate(ROUTE_LINKS.OpenLootbox(address))
          }}
        >
          <Trans>Open Box</Trans>
        </SoftButton>
        <Button
          variant="outlined"
          sx={{ pl: 2, pr: 2, borderColor: "#31372B" }}
          onClick={() => {
            navigate(ROUTE_LINKS.ManageLootbox(address))
          }}
        >
          <SettingsOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
          <Trans>Manage Box</Trans>
        </Button>
      </Box>
    </Box>
  )
}

export default LootBoxCard
