import { Box, styled, TextField, Typography } from "@mui/material"

export const MintAndTransferContainer = styled(Box)`
  background-color: #141414;
  border: 1px solid #262626;
  border-radius: 5px;
`

export const MintAndTransferBody = styled(Box)`
  border: 1px 0 1px 0;
  border-style: solid;
  border-color: #262626;
  padding: 32px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

export const MintAndTransferHeader = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
`

export const PlayerTransferRow = styled(Box)`
  display: flex;
  width: 100%;
  margin: 16px 0;
  align-items: flex-start;
`

export const PlayerAddressBox = styled(Box)`
  width: 45%;
`

export const NumberOfRewardsBox = styled(Box)`
  width: 25%;
`

export const NumberOfLootboxesBox = styled(Box)`
  width: 25%;
`

export const PlayerActionBox = styled(Box)`
  width: 5%;
`

export const PlayerAddressInputBox = styled(PlayerAddressBox)`
  margin-right: 16px;
`

export const RewardsInputBox = styled(NumberOfRewardsBox)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const RewardsInput = styled(TextField)`
  max-width: 120px;
`

export const CrossActionBox = styled(Box)`
  padding: 12px 0 0 12px;
  cursor: pointer;
`

export const NoPayerText = styled(Typography)`
  margin: 32px 0;
  color: #bfbfbf;
`

export const AddAddressText = styled(Typography)`
  margin-top: 16px;
  color: #bfbfbf;
  text-decoration: underline;
  cursor: pointer;
`

export const MintAndTransferActionBox = styled(Box)`
  padding: 32px;
  display: flex;
  justify-content: flex-end;
`
