import type { SvgIconProps } from "@mui/material"
import { SvgIcon } from "@mui/material"
import React from "react"

interface IIconProps extends SvgIconProps {
  fill?: string
}

export function LootboxesIcon(props: IIconProps): JSX.Element {
  const { fill = "#8C8C8C" } = props
  return (
    <SvgIcon {...props}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.75 4.58333V10.0833H7.33333L6.41667 6.41667L10.0833 7.33333V2.75H4.58333C3.575 2.75 2.75 3.575 2.75 4.58333ZM7.33333 11.9167H2.75V17.4167C2.75 18.425 3.575 19.25 4.58333 19.25H10.0833V14.6667L6.41667 15.5833L7.33333 11.9167ZM15.5833 15.5833L11.9167 14.6667V19.25H17.4167C18.425 19.25 19.25 18.425 19.25 17.4167V11.9167H14.6667L15.5833 15.5833ZM17.4167 2.75H11.9167V7.33333L15.5833 6.41667L14.6667 10.0833H19.25V4.58333C19.25 3.575 18.425 2.75 17.4167 2.75Z"
          fill={fill}
        />
      </svg>
    </SvgIcon>
  )
}
