import { Trans } from "@lingui/macro"
import { Box, Typography } from "@mui/material"
import { useConnectWallet } from "@web3-onboard/react"
import React from "react"

import { NoDataOrConnectionIcon } from "../../../assets/icons/NoDataOrConnectionIcon"
import { useCountly } from "../../../contexts/CountlyContext"
import { SoftButton } from "../../elements/SoftButton"

interface IProps {
  subtitle?: string
}

const WalletConnect: React.FC<IProps> = ({ subtitle }) => {
  const [, connect] = useConnectWallet()
  const { trackEvent } = useCountly()

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <NoDataOrConnectionIcon />
      <Typography textAlign="center" fontSize={20} mt={1}>
        <Trans>No wallet connected</Trans>
      </Typography>
      <Typography textAlign="center" variant="body1" mt={1} data-cy="label-wallet-connect-subtitle">
        <Trans>{subtitle || "To manage your LootBoxes, you must first connect a wallet."}</Trans>
      </Typography>
      <SoftButton
        variant="text"
        sx={{ mt: 3 }}
        size="small"
        onClick={() => {
          trackEvent({ key: "connected-wallet" })
          connect()
        }}
        data-cy="button-connect-wallet"
      >
        <Trans>Connect wallet</Trans>
      </SoftButton>
    </Box>
  )
}

export default WalletConnect
