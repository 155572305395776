import { Trans, t } from "@lingui/macro"
import { Box, Typography } from "@mui/material"
import type { BigNumber } from "ethers"
import { ethers } from "ethers"
import { useSnackbar } from "notistack"
import React, { useCallback, useEffect, useMemo, useState } from "react"

import { useCountly } from "../../../../contexts/CountlyContext"
import { useWeb3Connection } from "../../../../contexts/Web3ConnectionContext"
import Lootbox from "../../../../contracts/Lootbox/Lootbox.json"
import LootboxView from "../../../../contracts/Lootbox/LootboxView.json"

import InMyWallet from "./InMyWallet"
import OpenLootboxView from "./OpenLootboxView"
import type { ILootboxToken, IRewardEventArg, TOpenLootboxPage, IReward } from "./types"
import type { TokenType } from "../../../../types/lootbox"

interface IProps {
  lootboxToOpen: string
}

const ManageOpenLootbox: React.FC<IProps> = ({ lootboxToOpen }) => {
  const { address, provider } = useWeb3Connection()

  const { enqueueSnackbar } = useSnackbar()
  const { trackEvent } = useCountly()

  const [page, setPage] = useState<TOpenLootboxPage>("openLootbox")
  const [lootboxTokens, setLootboxTokens] = useState<ILootboxToken[]>([])

  const [isLoadingLootbox, setIsLoadingLootbox] = useState(true)
  const [isOpeningLootbox, setIsOpeningLootbox] = useState(false)
  const [openingLootboxMessage, setOpeningLootboxMessage] = useState("")
  const [lootboxType, setLootboxType] = useState<number | undefined>(undefined)
  const [gasNeeded, setGasNeeded] = useState<number | undefined>(undefined)
  const [openPrice, setOpenPrice] = useState<string>("")
  const [openPriceToView, setOpenPriceToView] = useState<string>("")

  const [eventArgs, setEventArgs] = useState<IRewardEventArg[]>([])

  const [openLootboxContract, openLootboxViewContract] = useMemo(() => {
    const signer = provider?.getSigner(0)
    return [
      new ethers.Contract(
        lootboxToOpen, // contract address
        Lootbox.abi, // contract abi (meta-data)
        signer // Signer object signs and sends transactions
      ),
      new ethers.Contract(
        lootboxToOpen, // contract address
        LootboxView.abi, // contract abi (meta-data)
        signer // Signer object signs and sends transactions
      ),
    ]
  }, [lootboxToOpen, provider])

  const getLootboxTokens = useCallback(async () => {
    if (!openLootboxViewContract) return
    try {
      const allowedTokens = (await openLootboxViewContract.getAllowedTokens()) as string[]
      const allowedTokenTypes =
        (await openLootboxViewContract.getAllowedTokenTypes()) as TokenType[]
      setLootboxTokens(
        allowedTokens.map((token, i) => ({ tokenAddress: token, tokenType: allowedTokenTypes[i] }))
      )
    } catch (err) {
      console.error(err)
      return
    }
  }, [openLootboxViewContract])

  // get lootbox type from balance of and iterations
  const getLootboxType = useCallback(async () => {
    if (!openLootboxContract || !openLootboxViewContract || !provider) return

    // get lootbox type
    const availableLootboxTypes = (await openLootboxViewContract.getLootboxTypes()).map(
      (l: BigNumber) => l.toNumber()
    )

    let newLootboxType: number | undefined
    for (const availableLootboxType of availableLootboxTypes) {
      const balance = await openLootboxContract.balanceOf(address, availableLootboxType)
      if (balance?.toNumber()) {
        newLootboxType = availableLootboxType
        setLootboxType(availableLootboxType)
        break
      }
    }

    // gas needed
    if (!newLootboxType) return
    const newGasNeeded = 150000 + 150000 * newLootboxType
    const gasPrice = await provider?.getGasPrice()

    const newOpenPrice: BigNumber = await openLootboxViewContract.calculateOpenPrice(
      newGasNeeded,
      gasPrice.mul(12).div(10),
      newLootboxType
    )

    const openPriceInEth = parseFloat(ethers.utils.formatEther(newOpenPrice).substring(0, 12)) * 2

    setOpenPrice(ethers.utils.parseUnits(openPriceInEth.toString()).toString())
    setOpenPriceToView(openPriceInEth.toString().substring(0, 8))

    setGasNeeded(newGasNeeded)
  }, [address, openLootboxContract, openLootboxViewContract, provider])

  useEffect(() => {
    if (!openLootboxContract || !openLootboxViewContract || !provider) return
    getLootboxType().finally(() => {
      setIsLoadingLootbox(false)
    })
    getLootboxTokens()
  }, [getLootboxType, getLootboxTokens, openLootboxContract, openLootboxViewContract, provider])

  const onOpenLootbox = useCallback(async () => {
    if (!openLootboxContract || !openPrice || !gasNeeded || !lootboxType) return
    try {
      setOpeningLootboxMessage("Please sign transaction to open lootbox, this can take some time !")
      setIsOpeningLootbox(true)

      // handle no link in contract
      const openGas = await openLootboxContract.estimateGas.open(gasNeeded, [lootboxType], [1], {
        value: openPrice,
      })

      const openTx = await openLootboxContract.open(gasNeeded, [lootboxType], [1], {
        value: openPrice,
        gasLimit: openGas.mul(11).div(10),
      })
      await openTx.wait()

      // track event
      trackEvent({
        key: "lootbox-opened",
        segmentation: { lootbox_address: lootboxToOpen },
      })

      setOpeningLootboxMessage("Checking for rewards claim, this can take some time !")

      let canClaimCheckCount = 1
      // call claim rewards in intervals
      const claimIntervalId = setInterval(async () => {
        try {
          // check can claim rewards
          const canClaimRewards = await openLootboxViewContract.canClaimRewards(address)
          canClaimCheckCount++

          if (canClaimRewards) {
            // close interval first
            clearInterval(claimIntervalId)

            // now we can claim rewards
            setOpeningLootboxMessage("Please sign transaction to claim rewards.")
            const rewardsTx = await openLootboxContract.claimRewards(address)
            const rewards = (await rewardsTx.wait()) as IReward

            const newEventArgs = rewards.events
              .filter((e) => e.event && e.args && e.event === "RewardsClaimed")
              .map((e) => e.args) as IRewardEventArg[]
            setEventArgs(newEventArgs)

            setPage("inMyWallet")
            setIsOpeningLootbox(false)
          }
          if (canClaimCheckCount > 15) {
            // close interval as we have spent
            // too much time checking
            clearInterval(claimIntervalId)
            setIsOpeningLootbox(false)
            enqueueSnackbar(t`Failed to open LootBox`, { variant: "error", autoHideDuration: 5000 })
          }
        } catch (err) {
          console.error(err)
          setIsOpeningLootbox(false)
          enqueueSnackbar(t`Failed to open LootBox`, { variant: "error", autoHideDuration: 5000 })
        }

        // 10s interval to check rewards claim
      }, 10000)
    } catch (err) {
      console.error(err)
      setIsOpeningLootbox(false)
      enqueueSnackbar(t`Failed to open LootBox`, { variant: "error", autoHideDuration: 5000 })
    }
  }, [
    openPrice,
    lootboxType,
    gasNeeded,
    address,
    openLootboxContract,
    openLootboxViewContract,
    enqueueSnackbar,
    trackEvent,
    lootboxToOpen,
  ])

  if (isLoadingLootbox) {
    return (
      <Box display="flex" mt={2} justifyContent="center">
        <Box
          sx={{
            width: "1000px",
            border: "1px solid #595959",
            borderRadius: "5px",
            p: 5,
          }}
        >
          <Typography>Loading LootBox ...</Typography>
        </Box>
      </Box>
    )
  }

  if (!lootboxType || !openPrice) {
    return (
      <Box display="flex" mt={2} justifyContent="center">
        <Box
          sx={{
            width: "1000px",
            border: "1px solid #595959",
            borderRadius: "5px",
            p: 5,
          }}
        >
          <Typography fontSize={20} sx={{ mb: 1 }}>
            <Trans>No LootBox has been minted for this user</Trans>
          </Typography>
          <Typography color="#BFBFBF">Wallet address: {address}</Typography>
        </Box>
      </Box>
    )
  }

  return page === "openLootbox" ? (
    <OpenLootboxView
      openPriceToView={openPriceToView}
      lootboxType={lootboxType}
      isOpeningLootbox={isOpeningLootbox}
      onOpenLootbox={onOpenLootbox}
      openingLootboxMessage={openingLootboxMessage}
    />
  ) : page === "inMyWallet" && !!eventArgs ? (
    <InMyWallet eventArgs={eventArgs} lootboxTokens={lootboxTokens} />
  ) : null
}

export default ManageOpenLootbox
