import { Trans } from "@lingui/macro"
import { Close, DriveFolderUpload } from "@mui/icons-material"
import { Box, Button, Divider, Switch, TextField, Typography } from "@mui/material"
import { useConnectWallet, useSetChain } from "@web3-onboard/react"
import { ethers } from "ethers"
import { useSnackbar } from "notistack"
import React, { useCallback, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useCountly } from "../../../../contexts/CountlyContext"
import { TOKEN_TYPE, useNFTs } from "../../../../contexts/NFTsContext"
import type { IUploadNFTRequest } from "../../../../contexts/StorageContext"
import { useStorageApi } from "../../../../contexts/StorageContext"
import { useWeb3Connection } from "../../../../contexts/Web3ConnectionContext"
import Minter1155 from "../../../../contracts/NFTLaunchPad/Minter1155.json"
import Minter721 from "../../../../contracts/NFTLaunchPad/Minter721.json"
import { ROUTE_LINKS } from "../../../../routes/routes"
import { getIPFSUri } from "../../../../utils/Helpers"
import ImageInput from "../../../elements/ImageInput"
import { SoftButton } from "../../../elements/SoftButton"

import MintAddCIDModal from "./MintAddCIDModal"
import MintSuccessModal from "./MintSuccessModal"


const NFTMint: React.FC = () => {
  const { isLoggedIn, network, provider, address } = useWeb3Connection()
  const [, setChain] = useSetChain()

  const { NFTsProject, NFTsCollection } = useNFTs()
  const { uploadNFTData } = useStorageApi()
  const [, connect] = useConnectWallet()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { trackEvent } = useCountly()

  const [isLoadingNFT, setIsLoadingNFT] = useState(false)
  const [isMintSuccess, setIsMintSuccess] = useState(false)

  const [NFTImages, setNFTImages] = useState<File[]>([])
  const additionalFileInput = useRef<HTMLInputElement>(null)
  const [additionalFiles, setAdditionalFiles] = useState<(File | string)[]>([])
  const [isCIDModalOpen, setIsCIDModalOpen] = useState(false)

  const [NFTName, setNFTName] = useState("")
  const [NFTSupply, setNFTSupply] = useState("1")
  const [NFTDescription, setNFTDescription] = useState("")
  const [isAdditionalPropertiesOpen, setIsAdditionalPropertiesOpen] = useState(false)
  const [additionalProperty, setAdditionalProperty] = useState<{ name: string; value: string }>({
    name: "",
    value: "",
  })
  const [additionalProperties, setAdditionalProperties] = useState<
    { name: string; value: string }[]
  >([])

  const onSubmitNFT = async (): Promise<void> => {
    // initial validation
    if (!NFTName.trim() || !NFTImages.length) {
      enqueueSnackbar("Please provide a NFT name and NFT image", {
        variant: "error",
      })
      return
    }

    if (!parseInt(NFTSupply) || parseInt(NFTSupply) <= 0) {
      enqueueSnackbar("Supply must be 1 or more", {
        variant: "error",
        id: "toast-error-invalid-supply",
      })
      return
    }

    if (!NFTsProject || !NFTsCollection || !NFTsCollection.contract_address || !address) return

    const signer = provider?.getSigner(0)
    const minter = new ethers.Contract(
      // contract address
      NFTsCollection.contract_address,
      // contract abi (meta-data)
      NFTsCollection.type === TOKEN_TYPE.ERC721 ? Minter721.abi : Minter1155.abi,
      // Signer object signs and sends transactions
      signer
    )

    try {
      // make sure to be on the correct chain
      if (network?.chainId !== NFTsCollection.chain_id) {
        const wasNetworkSet = await setChain({
          chainId: `0x${NFTsCollection.chain_id.toString(16)}`,
        })
        if (!wasNetworkSet) {
          enqueueSnackbar("Failed to switch network", { variant: "error" })
          return
        }
      }

      setIsLoadingNFT(true)

      const NFTRequest: IUploadNFTRequest = {
        name: NFTName.trim(),
        image: NFTImages[0],
        description: NFTDescription.trim(),
        tokenType: NFTsCollection.type,
        attributes: additionalProperties.map((a) => ({ trait_type: a.name, value: a.value })),
        additionalFiles: additionalFiles,
      }

      // upload NFT to storage
      uploadNFTData(NFTRequest)
        .then(async (cid) => {
          if (cid) {
            // we have CID from storage
            const tokenUri = getIPFSUri(cid)

            const NFTTx = await (NFTsCollection.type === TOKEN_TYPE.ERC721
              ? minter.mint(address, tokenUri)
              : minter.mint(address, tokenUri, NFTSupply || "1"))
            await NFTTx

            // track event
            trackEvent({
              key: "nft-minted",
              segmentation: {
                project_id: NFTsProject.projectId,
                collection_id: NFTsCollection.id,
              },
            })

            // minting done
            enqueueSnackbar("NFT minted successfully", {
              variant: "success",
              id: "toast-success-mint-nft",
            })
            setIsMintSuccess(true)
          } else {
            enqueueSnackbar("Failed to upload NFT data", {
              variant: "error",
              id: "toast-error-upload-nft-data",
            })
            setIsLoadingNFT(false)
          }
        })
        .catch((err) => {
          console.error(err)
          enqueueSnackbar("Failed to upload NFT data", {
            variant: "error",
            id: "toast-error-upload-nft-data",
          })
          setIsLoadingNFT(false)
        })
    } catch (err) {
      console.error(err)
      setIsLoadingNFT(false)
      enqueueSnackbar("Failed to mint NFT", { variant: "error", id: "toast-error-mint-nft" })
    }
  }

  const onSubmitAdditionalProperty = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      if (!additionalProperty.name.trim() || !additionalProperty.value.trim()) {
        enqueueSnackbar("Property name and value are required", {
          variant: "error",
          id: "toast-error-empty-property",
        })
        return
      }
      if (additionalProperties.find((a) => a.name === additionalProperty.name)) {
        enqueueSnackbar("Property with same name exists", {
          variant: "error",
          id: "toast-error-duplicate-property",
        })
        return
      }
      setAdditionalProperty({ name: "", value: "" })
      additionalProperties.push(additionalProperty)
    },
    [additionalProperties, additionalProperty, enqueueSnackbar]
  )

  if (!NFTsCollection || !NFTsProject) return null

  return (
    <>
      <Box display="flex" justifyContent="center" mt={4} mb={16}>
        <Box
          width={960}
          sx={{ borderRadius: "8px", border: "1px solid #262626", background: "#141414" }}
        >
          <Box p={4} pb={2}>
            <Typography fontSize={28}>
              <Trans>Mint a new NFT</Trans>
            </Typography>
            <Typography sx={{ mt: 1 }}>
              <Trans>Upload your artwork to begin the creation of your NFT(s).</Trans>
            </Typography>
          </Box>
          <Divider />
          <Box mt={3} pl={4} pr={4} mb={4}>
            <Typography fontSize="13px" sx={{ mb: 1 }}>
              <Trans>Upload file in .jpg, .png, .svg or .gif format</Trans>
            </Typography>
            <ImageInput
              images={NFTImages}
              setImages={setNFTImages}
              fitType="contain"
              testId="new-nft"
            />
          </Box>
          <Divider />
          <Box pl={4} pr={4} pt={3} pb={4}>
            <Typography fontSize={18} color="#DFF7C7" pb={2}>
              <Trans>Basic Information</Trans>
            </Typography>
            <Box display="flex" flexDirection="column" gap={2}>
              <Box
                display="grid"
                gap={2}
                sx={{
                  gridTemplateColumns: "2fr 1fr 1fr",
                }}
              >
                <TextField
                  label="Name"
                  size="small"
                  value={NFTName}
                  onChange={(e) => setNFTName(e.target.value)}
                  inputProps={{ maxLength: 100 }}
                  data-cy="input-new-nft-name"
                />
                <TextField
                  label="Type"
                  size="small"
                  disabled
                  value={NFTsCollection.type}
                  data-cy="input-new-nft-type"
                />
                <TextField
                  label="Supply"
                  size="small"
                  value={NFTSupply}
                  disabled={NFTsCollection.type === TOKEN_TYPE.ERC721}
                  onChange={(e) => setNFTSupply(e.target.value)}
                  data-cy="input-new-nft-supply"
                />
              </Box>
              <TextField
                label="Description (Optional)"
                multiline
                rows={2}
                size="small"
                inputProps={{ maxLength: 2000 }}
                value={NFTDescription}
                onChange={(e) => setNFTDescription(e.target.value)}
                data-cy="input-new-nft-description"
              />
            </Box>
          </Box>
          <Divider />
          <Box pl={4} pr={4} pt={3} pb={4}>
            <Typography fontSize={18} color="#DFF7C7" pb={2}>
              <Trans>Additional Media</Trans>
            </Typography>

            <Box mb={1.5} sx={{ border: "1px solid #262626" }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pt={0.5}
                pb={0.5}
                pl={3}
                pr={3}
                height={48}
                sx={{ background: "#262626", borderRadius: "4px" }}
              >
                <Box display="flex">
                  <Typography mr={0.5}>
                    <Trans>Additional files</Trans>
                  </Typography>
                  <Typography color="#999999">
                    <Trans>(optional)</Trans>
                  </Typography>
                </Box>
                <Box display="flex" gap={1.5}>
                  <input
                    type="file"
                    multiple={true}
                    onChange={(e) => {
                      if (e.target.files?.length) {
                        setAdditionalFiles((prevState) => [
                          ...prevState,
                          ...Array.from(e.target.files || []),
                        ])
                      }
                    }}
                    accept="image/*"
                    ref={additionalFileInput}
                    style={{ display: "none" }}
                    data-cy="file-new-nft-additional-file"
                  />
                  <Button
                    sx={{
                      background: "#595959",
                      p: 0.5,
                      minWidth: "36px",
                      minHeight: "32px",
                      "&:hover": {
                        background: "#595959",
                      },
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      additionalFileInput?.current?.click()
                    }}
                  >
                    <DriveFolderUpload sx={{ fontSize: "18px" }} />
                  </Button>
                  <Button
                    sx={{
                      background: "#595959",
                      p: 0.5,
                      minWidth: "72px",
                      minHeight: "32px",
                      "&:hover": {
                        background: "#595959",
                      },
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      setIsCIDModalOpen(true)
                    }}
                    data-cy="button-new-nft-add-cid"
                  >
                    Add CID
                  </Button>
                </Box>
              </Box>
              {additionalFiles.length ? (
                <Box p={3}>
                  <Box display="flex" mb={1}>
                    <Typography width="25%" color="#8C8C8C">
                      <Trans>File type</Trans>
                    </Typography>
                    <Typography color="#8C8C8C">
                      <Trans>Name</Trans>
                    </Typography>
                  </Box>
                  {additionalFiles.map((additionalFile) => {
                    const additionalFileName =
                      typeof additionalFile === "string" ? additionalFile : additionalFile.name

                    return (
                      <Box key={additionalFileName} display="flex" mb={1} alignItems="center">
                        <Typography width="25%" data-cy="label-new-nft-additional-file-type">
                          <Trans>{typeof additionalFile === "string" ? "CID" : "File"}</Trans>
                        </Typography>
                        <Typography
                          sx={{
                            flex: 1,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            mr: 5,
                          }}
                          data-cy="label-new-nft-additional-file-name"
                        >
                          {additionalFileName}
                        </Typography>
                        <Close
                          sx={{
                            fontSize: "16px",
                            cursor: "pointer",
                            background: "#595959",
                            color: "#000000",
                            borderRadius: "50%",
                          }}
                          onClick={() => {
                            setAdditionalFiles((prevAdditionalFiles) =>
                              prevAdditionalFiles.filter((otherAdditionalFile) => {
                                const otherAdditionalFileName =
                                  typeof otherAdditionalFile === "string"
                                    ? otherAdditionalFile
                                    : otherAdditionalFile.name

                                return additionalFileName !== otherAdditionalFileName
                              })
                            )
                          }}
                          data-cy="icon-new-nft-additional-file-close"
                        />
                      </Box>
                    )
                  })}
                </Box>
              ) : null}
            </Box>

            <Box sx={{ border: "1px solid #262626" }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pt={0.5}
                pb={0.5}
                pl={3}
                pr={3}
                height={48}
                sx={{ background: "#262626", borderRadius: "4px" }}
              >
                <Box display="flex">
                  <Typography mr={0.5}>
                    <Trans>Additional properties</Trans>
                  </Typography>
                  <Typography color="#999999">
                    <Trans>(optional)</Trans>
                  </Typography>
                </Box>
                <Switch
                  checked={isAdditionalPropertiesOpen}
                  onChange={() => {
                    setIsAdditionalPropertiesOpen((prevState) => !prevState)
                  }}
                  data-cy="checkbox-new-nft-additional-properties"
                />
              </Box>
              {isAdditionalPropertiesOpen ? (
                <Box p={3}>
                  <Box>
                    <form
                      style={{ display: "flex", alignItems: "center", gap: 16 }}
                      onSubmit={onSubmitAdditionalProperty}
                    >
                      <TextField
                        size="small"
                        sx={{ flex: 1 }}
                        label="Property Field Type"
                        placeholder="Property Field Type"
                        value={additionalProperty.name}
                        onChange={(e) => {
                          setAdditionalProperty((prevState) => ({
                            ...prevState,
                            name: e.target.value,
                          }))
                        }}
                        data-cy="input-new-nft-property-field-type"
                      />
                      <TextField
                        size="small"
                        sx={{ flex: 1 }}
                        label="Property Value"
                        placeholder="Property Value"
                        value={additionalProperty.value}
                        onChange={(e) => {
                          setAdditionalProperty((prevState) => ({
                            ...prevState,
                            value: e.target.value,
                          }))
                        }}
                        data-cy="input-new-nft-property-value"
                      />
                      <SoftButton
                        sx={{ background: "#595959", height: 36 }}
                        disabled={
                          !additionalProperty.name.trim() || !additionalProperty.value.trim()
                        }
                        type="submit"
                        data-cy="button-new-nft-additional-property-add"
                      >
                        <Trans>Add</Trans>
                      </SoftButton>
                    </form>
                  </Box>
                  <Box mt={2} display="flex" gap={2} flexWrap="wrap">
                    {additionalProperties.map((additionalPropertyData) => (
                      <Box
                        key={additionalPropertyData.name}
                        sx={{ background: "#595959", borderRadius: "24px" }}
                        width={160}
                        pl={2.5}
                        pr={2.5}
                        pt={1.5}
                        pb={1.5}
                      >
                        <Box display="flex" justifyContent="space-between">
                          <Typography
                            variant="body1"
                            data-cy="label-new-nft-additional-property-name"
                          >
                            {additionalPropertyData.name}
                          </Typography>
                          <Close
                            sx={{ fontSize: "16px", cursor: "pointer" }}
                            onClick={() => {
                              setAdditionalProperties((preState) =>
                                preState.filter((a) => a.name !== additionalPropertyData.name)
                              )
                            }}
                            data-cy="icon-new-nft-additional-property-close"
                          />
                        </Box>
                        <Typography
                          variant="body1"
                          color="#B4FF68"
                          data-cy="label-new-nft-additional-property-value"
                        >
                          {additionalPropertyData.value}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={3} pr={4} gap={2}>
            <Button
              onClick={() => {
                if (NFTsProject?.projectId) {
                  navigate(ROUTE_LINKS.NFTsOnCollection(NFTsProject.projectId, NFTsCollection.id))
                }
              }}
              sx={{ background: "#262626", color: "#FFFFFF", pl: 2, pr: 2 }}
              data-cy="button-new-nft-cancel"
            >
              Cancel
            </Button>
            {isLoggedIn ? (
              <SoftButton
                loading={isLoadingNFT}
                onClick={onSubmitNFT}
                disabled={!NFTName.trim() || !NFTImages.length}
                data-cy="button-new-nft-mint-nft"
              >
                <Trans>Mint NFT</Trans>
              </SoftButton>
            ) : (
              <SoftButton onClick={() => connect()} data-cy="button-new-nft-connect-wallet">
                <Trans>Connect wallet</Trans>
              </SoftButton>
            )}
          </Box>
        </Box>
      </Box>
      <MintSuccessModal
        isOpen={isMintSuccess}
        onOk={() => {
          navigate(ROUTE_LINKS.NFTsOnCollection(NFTsProject.projectId, NFTsCollection.id))
        }}
      />
      <MintAddCIDModal
        isOpen={isCIDModalOpen}
        onCancel={() => {
          setIsCIDModalOpen(false)
        }}
        onAddCIDs={(CIDs?: string[]) => {
          if (CIDs) {
            setAdditionalFiles((prevState) => Array.from(new Set([...prevState, ...CIDs])))
          }
          setIsCIDModalOpen(false)
        }}
      />
    </>
  )
}

export default NFTMint
