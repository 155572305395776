import { Trans } from "@lingui/macro"
import { Box, CircularProgress, Typography } from "@mui/material"
import React from "react"

import { NoLootboxIcon } from "../../../assets/icons/NoLootboxIcon"
import { useLootbox } from "../../../contexts/LootboxContext"
import { useWeb3Connection } from "../../../contexts/Web3ConnectionContext"

import LootBoxCard from "./LootboxCard"

const AllLootBoxes: React.FC = () => {
  const { isLoadingLootboxes, lootboxes, networkData } = useLootbox()
  const { isLoggedIn } = useWeb3Connection()

  if (isLoggedIn && !networkData) {
    return (
      <Box mt={6} display="flex" flexDirection="column" alignItems="center">
        <Typography fontSize={24}>Current network not supported</Typography>
        <Typography>Please switch networks</Typography>
      </Box>
    )
  }

  if (isLoadingLootboxes) {
    return (
      <Box display="flex" justifyContent="center" mt={8}>
        <CircularProgress />
      </Box>
    )
  }

  if (!lootboxes?.length) {
    return (
      <Box>
        <Box
          p={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <NoLootboxIcon />
          <Typography mt={1} fontSize={18}>
            <Trans>No LootBoxes Deployed</Trans>
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box pl={2} pr={2} display="flex" flexDirection="column" gap={2}>
      <Box>
        <Typography variant="h5" mb={1.5}>
          <Trans>Deployed LootBoxes</Trans>
        </Typography>
      </Box>
      {lootboxes.map((lootbox, index) => (
        <LootBoxCard key={lootbox.address} lootbox={lootbox} serial={index + 1} />
      ))}
    </Box>
  )
}

export default AllLootBoxes
