import { Box } from "@mui/material"
import React from "react"

import {
  ActionButton,
  HeadingText,
  HeadingTextContainer,
  ManageHeaderContainer,
  SubtitleText,
} from "./ManageHeader.styles"

interface IManageLootboxHeaderProps {
  heading: string
  subtitle?: string
  action?: {
    text: string
    onClick: () => void
  }
}

const ManageLootboxHeader: React.FC<IManageLootboxHeaderProps> = ({
  heading,
  subtitle,
  action,
}) => {
  return (
    <ManageHeaderContainer>
      <HeadingTextContainer>
        <Box>
          <HeadingText>{heading}</HeadingText>
        </Box>
        {action && <ActionButton onClick={action.onClick}>{action.text}</ActionButton>}
      </HeadingTextContainer>
      {subtitle ? <SubtitleText>{subtitle}</SubtitleText> : null}
    </ManageHeaderContainer>
  )
}

export default ManageLootboxHeader
