import { Trans, t } from "@lingui/macro"
import { Box, Typography } from "@mui/material"

import { useLootbox } from "../../../../contexts/LootboxContext"

import ManageLootboxHeader from "./ManageHeader"

const Review = (): JSX.Element => {
  const { lootboxAllowedTokens, lootboxSuppliers, lootboxInventory } = useLootbox()

  return (
    <Box bgcolor="#141414" sx={{ border: "1px solid #262626", borderRadius: "5px" }}>
      <ManageLootboxHeader
        heading={t`Review summary`}
        subtitle={t`Review your inputs before finalizing your LootBox configurations. You can also change these settings later in the Overview page.`}
      />
      <Box
        sx={{
          borderTop: "1px solid #262626",
          borderBottom: "1px solid #262626",
        }}
        p={4}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        {/* token */}
        <Box mb={2}>
          <Typography pb={0.5}>
            <Trans>Token addresses</Trans>
          </Typography>
          {lootboxAllowedTokens?.length ? (
            lootboxAllowedTokens?.map((lootboxAllowedToken, i) => (
              <Box display="flex" key={lootboxAllowedToken}>
                <Typography color="#BFBFBF" mr={1}>
                  Address {i + 1}:
                </Typography>
                <Typography color="#B4FF68">{lootboxAllowedToken}</Typography>
              </Box>
            ))
          ) : (
            <Typography color="#BFBFBF">No token addresses</Typography>
          )}
        </Box>

        {/* depositor */}
        <Box mb={2}>
          <Typography pb={0.5}>
            <Trans>Review depositor addresses</Trans>
          </Typography>
          {lootboxSuppliers?.length ? (
            lootboxSuppliers?.map((lootboxSupplier, i) => (
              <Box display="flex" key={lootboxSupplier}>
                <Typography color="#BFBFBF" mr={1}>
                  Address {i + 1}:
                </Typography>
                <Typography color="#B4FF68">{lootboxSupplier}</Typography>
              </Box>
            ))
          ) : (
            <Typography color="#BFBFBF">No depositor addresses</Typography>
          )}
        </Box>

        {/* rewards  */}
        <Box mb={2}>
          <Typography pb={0.5}>
            <Trans>Confirm reward type and units</Trans>
          </Typography>
          <Typography mr={1}>
            {(lootboxInventory?.result.length || 0) +
              (lootboxInventory?.leftoversResult?.length || 0) || "no"}{" "}
            rewards
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Review
