import { useLootbox } from "../../../../contexts/LootboxContext"

import ManageOpenLootbox from "./ManageOpenLootbox"

const OpenLootboxModule = (): JSX.Element | null => {
  const { lootboxToOpen } = useLootbox()

  if (!lootboxToOpen) {
    return null
  }

  return <ManageOpenLootbox lootboxToOpen={lootboxToOpen} />
}

export default OpenLootboxModule
