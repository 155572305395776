import { Trans, t } from "@lingui/macro"
import { CircularProgress, Typography } from "@mui/material"
import { useCallback } from "react"

import { useLootbox } from "../../../../../contexts/LootboxContext"
import { SoftButton } from "../../../../elements/SoftButton"
import ManageLootboxHeader from "../ManageHeader"

import { useRewardUnits } from "./hooks/useRewardUnits"
import {
  LoadingContainer,
  NoRewardUnitsText,
  RewardInputs,
  RewardRemainingBalanceText,
  RewardTypeText,
  RewardUnitAddress,
  RewardUnitAddressContainer,
  RewardUnitInputContainer,
  RewardUnitsBody,
  RewardUnitsBox,
  RewardUnitsContainer,
  RewardUnitsFooter,
  RewardUnitsHeading,
  RewardUnitsLayout,
  RewardUnitsRow,
} from "./styles/RewardUnits.styles"
import { TOKEN_TYPE_KEYS, TOKEN_TYPES } from "../../../../../types/lootbox"
import VarERC20Modal from "./VarERC20Modal"

const RewardUnits: React.FC = () => {
  const { isInventoryLoading } = useLootbox()
  const {
    network,
    isRewardsLoading,
    isRewardsProcessing,
    updateLootboxRewards,
    lootboxRewards,
    setLootboxRewards,
    setHasRewardsChanged,
    hasRewardsChanged,
    setIsVarERC20ModalOpen,
    isVarERC20ModalOpen,
  } = useRewardUnits()

  const updateRewardInputs = useCallback(
    (index: number, value: string) => {
      lootboxRewards[index].amountPerUnit = value
      setLootboxRewards([...lootboxRewards])
      setHasRewardsChanged(true)
    },
    [lootboxRewards, setHasRewardsChanged, setLootboxRewards]
  )

  if (isInventoryLoading || isRewardsProcessing) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    )
  }

  return (
    <RewardUnitsLayout>
      <RewardUnitsBox>
        <ManageLootboxHeader
          heading={t`Manage Reward Units`}
          subtitle={t`Specify how much of each token is distributed per reward item (default 1).`}
        />
        <RewardUnitsBody>
          <RewardUnitsContainer>
            <RewardUnitsHeading>
              <Typography width="60%">
                <Trans>Loot token contracts</Trans>
              </Typography>
              <Typography width="20%" align="center">
                <Trans>Reward type</Trans>
              </Typography>
              <Typography width="20%" align="center">
                <Trans># of rewards</Trans>
              </Typography>
            </RewardUnitsHeading>
            {lootboxRewards?.length ? (
              lootboxRewards.map((lootboxReward, index) => (
                <RewardUnitsRow
                  key={`${lootboxReward.tokenAddress}${lootboxReward.tokenId ? lootboxReward.tokenId.toString() : ""}`}
                >
                  <RewardUnitAddressContainer>
                    <RewardUnitAddress>
                      {TOKEN_TYPE_KEYS.ERC1155.some(
                        (tokenType) => tokenType === lootboxReward.rewardType
                      ) && lootboxReward.tokenId !== undefined
                        ? `${lootboxReward.tokenId} - `
                        : ""}
                      {lootboxReward.tokenAddress}
                    </RewardUnitAddress>
                    <RewardRemainingBalanceText align="center">
                      Remaining balance: {lootboxReward.remainingBalance}
                    </RewardRemainingBalanceText>
                  </RewardUnitAddressContainer>
                  <RewardUnitInputContainer>
                    <RewardTypeText>{TOKEN_TYPES[lootboxReward.rewardType]}</RewardTypeText>
                  </RewardUnitInputContainer>
                  <RewardUnitInputContainer>
                    <RewardInputs
                      fullWidth
                      size="small"
                      placeholder={t`# of rewards`}
                      value={lootboxReward.amountPerUnit}
                      onChange={(e) => {
                        updateRewardInputs(index, e.target.value)
                      }}
                    />
                  </RewardUnitInputContainer>
                </RewardUnitsRow>
              ))
            ) : (
              <NoRewardUnitsText>
                <Trans>No reward units added</Trans>
              </NoRewardUnitsText>
            )}
            <RewardUnitsFooter>
              <SoftButton
                onClick={updateLootboxRewards}
                loading={isRewardsLoading}
                disabled={!hasRewardsChanged}
              >
                <Trans>Save changes</Trans>
              </SoftButton>
            </RewardUnitsFooter>
          </RewardUnitsContainer>
        </RewardUnitsBody>
      </RewardUnitsBox>
      {network?.lootboxERC20WrapperAddress ? (
        <RewardUnitsBox>
          <ManageLootboxHeader
            heading={t`Variable ERC20 Reward Units`}
            subtitle={t`Create multiple bundles of ERC20 tokens`}
            action={{
              text: t`Add Variable ERC20 Address`,
              onClick: () => {
                setIsVarERC20ModalOpen(true)
              },
            }}
          />
        </RewardUnitsBox>
      ) : null}
      <VarERC20Modal open={isVarERC20ModalOpen} onClose={() => setIsVarERC20ModalOpen(false)} />
    </RewardUnitsLayout>
  )
}

export default RewardUnits
