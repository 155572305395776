import { t } from "@lingui/macro"
import { BigNumber, ethers } from "ethers"
import { useSnackbar } from "notistack"
import type { ChangeEvent } from "react"
import { useCallback, useEffect, useMemo, useState } from "react"

import { useLootbox } from "../../../../../../contexts/LootboxContext"
import { useWeb3Connection } from "../../../../../../contexts/Web3ConnectionContext"
import GenericERC20 from "../../../../../../contracts/Generic/GenericERC20.json"
import ERC1155ERC20WrapperFactory from "../../../../../../contracts/Lootbox/ERC1155ERC20WrapperFactory.json"
import LootboxAbi from "../../../../../../contracts/Lootbox/Lootbox.json"
import LootboxViewAbi from "../../../../../../contracts/Lootbox/LootboxView.json"
import type { ERC20DistributionHook, ERC20ModalStep, ITokenBundles } from "../types"

export const useERC20Distributions = (): ERC20DistributionHook => {
  const { network, address, signer } = useWeb3Connection()
  const { lootboxToManage, lootboxInventory, activateERC20Distributions } = useLootbox()
  const [tokenAddress, setTokenAddress] = useState("")
  const [isTokenAddressValid, setIsTokenAddressValid] = useState(false)

  const [wrapperAddress, setWrapperAddress] = useState("")
  const [step, setStep] = useState<ERC20ModalStep>(1)
  const [tokenBundles, setTokenBundles] = useState<ITokenBundles[]>([
    { numberOfTokens: "", numberOfBundles: "" },
    { numberOfTokens: "", numberOfBundles: "" },
  ])
  const [isTxLoading, setIsTxLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const wrapperContract = useMemo(
    () =>
      new ethers.Contract(
        network?.lootboxERC20WrapperAddress || "",
        ERC1155ERC20WrapperFactory.abi,
        signer
      ),
    [network, signer]
  )

  const lootboxContract = useMemo(
    () =>
      lootboxToManage
        ? new ethers.Contract(lootboxToManage?.address, LootboxAbi.abi, signer)
        : undefined,
    [signer, lootboxToManage]
  )

  const lootboxViewContract = useMemo(
    () =>
      lootboxToManage
        ? new ethers.Contract(lootboxToManage?.address, LootboxViewAbi.abi, signer)
        : undefined,
    [signer, lootboxToManage]
  )

  // check is token address to be whitelisted is valid
  useEffect(() => {
    setIsTokenAddressValid(!!tokenAddress && ethers.utils.isAddress(tokenAddress))
  }, [tokenAddress])

  const whitelistToken = useCallback(async (): Promise<void> => {
    try {
      if (!tokenAddress || !isTokenAddressValid || !lootboxContract || !lootboxViewContract) return

      setIsTxLoading(true)
      const erc20WrapperCloneAddress = await wrapperContract.getDeployedAddress(
        address,
        tokenAddress
      )

      const lootboxTokens = (await lootboxViewContract.getAllowedTokens()) as string[]

      // token already added
      if (
        lootboxInventory &&
        [...(lootboxInventory.result || []), ...(lootboxInventory.leftoversResult || [])].find(
          (inventory) => inventory.rewardToken === erc20WrapperCloneAddress
        )
      ) {
        enqueueSnackbar(t`ERC20 address already added to lootbox`, { variant: "error" })
        setIsTxLoading(false)
        return
      }

      if (!lootboxTokens?.includes(erc20WrapperCloneAddress)) {
        const tokenAddressTx = await lootboxContract.addTokens([erc20WrapperCloneAddress])
        await tokenAddressTx
      }

      setWrapperAddress(erc20WrapperCloneAddress)
      setIsTxLoading(false)
      setStep(2)
      enqueueSnackbar(t`ERC20 address whitelisted`, { variant: "success" })
      return erc20WrapperCloneAddress
    } catch (error) {
      console.error(error)
      setIsTxLoading(false)
      enqueueSnackbar(t`Failed to whitelist ERC20 address`, { variant: "error" })
    }
  }, [
    address,
    enqueueSnackbar,
    isTokenAddressValid,
    lootboxContract,
    lootboxViewContract,
    tokenAddress,
    wrapperContract,
    lootboxInventory,
  ])

  const approveToken = useCallback(async (): Promise<void> => {
    try {
      if (!wrapperAddress || !lootboxContract || !lootboxViewContract || !address) return
      const erc20Contract = new ethers.Contract(tokenAddress, GenericERC20, signer)
      setIsTxLoading(true)

      const suppliers = (await lootboxViewContract.getSuppliers()) as string[]
      if (!suppliers.map((supplier) => supplier.toLowerCase()).includes(address?.toLowerCase())) {
        const supplierTx = await lootboxContract.addSuppliers([address])
        await supplierTx
      }

      const allowance = (await erc20Contract.allowance(address, wrapperAddress)) as BigNumber

      if (allowance.lt(BigNumber.from(1000))) {
        const approveTx = await erc20Contract.approve(
          wrapperAddress,
          ethers.constants.MaxUint256.sub(BigNumber.from(1))
        )
        await approveTx
      }

      setIsTxLoading(false)
      setStep(3)
      enqueueSnackbar(t`LootBox approved to spend ERC20`, { variant: "success" })
    } catch (error) {
      console.error(error)
      setIsTxLoading(false)
      enqueueSnackbar(t`Failed to approve LootBox to spend ERC20`, { variant: "error" })
    }
  }, [
    address,
    enqueueSnackbar,
    lootboxContract,
    lootboxViewContract,
    signer,
    tokenAddress,
    wrapperAddress,
  ])

  const addBundle = useCallback((): void => {
    setTokenBundles((prevState) => [...prevState, { numberOfTokens: "", numberOfBundles: "" }])
  }, [])

  const updateTokenNumber = useCallback(
    (
      { target: { value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index: number
    ): void => {
      setTokenBundles((prevState) => [
        ...prevState.map((p, i) =>
          i === index
            ? {
                numberOfTokens: value,
                numberOfBundles: p.numberOfBundles,
              }
            : p
        ),
      ])
    },
    []
  )

  const updateTokenBundle = useCallback(
    (
      { target: { value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index: number
    ): void => {
      setTokenBundles((prevState) => [
        ...prevState.map((p, i) =>
          i === index
            ? {
                numberOfTokens: p.numberOfTokens,
                numberOfBundles: value,
              }
            : p
        ),
      ])
    },
    []
  )

  const removeBundle = useCallback((index: number): void => {
    setTokenBundles((prevState) => {
      prevState.splice(index, 1)
      return [...prevState]
    })
  }, [])

  const activateVariableDistributions = useCallback(async (): Promise<boolean | undefined> => {
    if (!tokenAddress || !lootboxToManage?.address || !tokenBundles.length || !wrapperAddress)
      return

    if (tokenBundles.some((tokenBundle) => tokenBundle.numberOfBundles.includes("."))) {
      enqueueSnackbar(t`Number of bundles must be whole numbers`, { variant: "error" })
      return
    }

    const tokens = tokenBundles.map((tokenBundle) => parseFloat(tokenBundle.numberOfTokens))
    const bundles = tokenBundles.map((tokenBundle) => parseInt(tokenBundle.numberOfBundles))

    if (!tokens.length || !bundles.length || bundles.length !== tokens.length) {
      enqueueSnackbar(t`Number of tokens and bundles must be valid`, { variant: "error" })
      return
    }

    if (tokens.some((token) => !token || Number.isNaN(token) || !(token > 0))) {
      enqueueSnackbar(t`Number of tokens must be valid`, { variant: "error" })
      return
    }
    if (bundles.some((bundle) => !bundle || Number.isNaN(bundle) || !(bundle > 1))) {
      enqueueSnackbar(t`Number of bundles must be valid and greater than 1`, { variant: "error" })
      return
    }
    if (new Set(tokens).size !== tokens.length) {
      enqueueSnackbar(t`Number of tokens must be unique for each row`, { variant: "error" })
      return
    }

    // token already added
    if (
      lootboxInventory &&
      [...(lootboxInventory.result || []), ...(lootboxInventory.leftoversResult || [])].find(
        (inventory) => inventory.rewardToken === wrapperAddress
      )
    ) {
      enqueueSnackbar(t`ERC20 address already added to lootbox`, { variant: "error" })
      return
    }

    setIsTxLoading(true)

    try {
      await activateERC20Distributions(tokenAddress, tokens, bundles)
      enqueueSnackbar(t`ERC20 distributions created successfully`, { variant: "success" })
      enqueueSnackbar(t`It may take some time rewards to appear`, { variant: "success" })
      setIsTxLoading(false)
      return true
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" })
      setIsTxLoading(false)
      return
    }
  }, [
    enqueueSnackbar,
    lootboxToManage,
    tokenAddress,
    tokenBundles,
    wrapperAddress,
    lootboxInventory,
    activateERC20Distributions,
  ])

  const clearStates = (): void => {
    setStep(1)
    setTokenAddress("")
    setTokenBundles([{ numberOfTokens: "", numberOfBundles: "" }])
    setIsTxLoading(false)
  }

  return {
    step,
    setStep,
    tokenAddress,
    setTokenAddress,
    isTokenAddressValid,
    tokenBundles,
    setTokenBundles,
    isTxLoading,
    whitelistToken,
    approveToken,
    updateTokenBundle,
    updateTokenNumber,
    addBundle,
    removeBundle,
    activateVariableDistributions,
    clearStates,
  }
}
