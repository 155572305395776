import { t } from "@lingui/macro"
import { ethers } from "ethers"
import { useSnackbar } from "notistack"
import { useState, useCallback } from "react"

import { useLootbox } from "../../../../../contexts/LootboxContext"

export interface IMintAndTransferData {
  id: number
  playerAddress: string
  numberOfRewards: number
  numberOfLootboxes: number
}

type MintAndTransferHook = {
  playerMints: IMintAndTransferData[]
  isMintsLoading: boolean
  playerMintsErrors: string[]
  updatePlayerAddress: (playerMintId: number, address: string) => void
  updateNumberOfRewards: (playerMintId: number, numberOfRewards: string) => void
  updateNumberOfLootboxes: (playerMintId: number, numberOfLootboxes: string) => void
  removeMintAndTransferData: (playerMintId: number) => void
  addMintAndTransferData: () => void
  mintAndTransfer: () => Promise<void>
}

export const useMintAndTransfer = (): MintAndTransferHook => {
  const { mintAndTransferToPlayers } = useLootbox()
  const { enqueueSnackbar } = useSnackbar()

  const [isMintsLoading, setIsMintsLoading] = useState(false)
  const [playerMints, setPlayerMints] = useState<IMintAndTransferData[]>([
    { id: 1, playerAddress: "", numberOfRewards: 1, numberOfLootboxes: 1 },
  ])
  const [playerMintsErrors, setPlayerMintsErrors] = useState<string[]>([])

  const updatePlayerAddress = useCallback((playerMintId: number, address: string) => {
    setPlayerMints((prevPlayerMints) =>
      prevPlayerMints.map((prevPlayerMint) =>
        prevPlayerMint.id === playerMintId
          ? {
              ...prevPlayerMint,
              playerAddress: address,
            }
          : prevPlayerMint
      )
    )
  }, [])

  const updateNumberOfRewards = useCallback((playerMintId: number, numberOfRewards: string) => {
    if (numberOfRewards && !parseInt(numberOfRewards)) return
    setPlayerMints((prevPlayerMints) =>
      prevPlayerMints.map((prevPlayerMint) =>
        prevPlayerMint.id === playerMintId
          ? {
              ...prevPlayerMint,
              numberOfRewards: parseInt(numberOfRewards) || 0,
            }
          : prevPlayerMint
      )
    )
  }, [])

  const updateNumberOfLootboxes = useCallback((playerMintId: number, numberOfLootboxes: string) => {
    if (numberOfLootboxes && !parseInt(numberOfLootboxes)) return
    setPlayerMints((prevPlayerMints) =>
      prevPlayerMints.map((prevPlayerMint) =>
        prevPlayerMint.id === playerMintId
          ? {
              ...prevPlayerMint,
              numberOfLootboxes: parseInt(numberOfLootboxes) || 0,
            }
          : prevPlayerMint
      )
    )
  }, [])

  const removeMintAndTransferData = useCallback((playerMintId: number) => {
    setPlayerMints((prevPlayerMints) =>
      prevPlayerMints.filter((prevPlayerMint) => prevPlayerMint.id !== playerMintId)
    )
    setPlayerMintsErrors([])
  }, [])

  const addMintAndTransferData = useCallback(() => {
    setPlayerMints((prevPlayerMints) => [
      ...prevPlayerMints,
      {
        id: prevPlayerMints.length ? prevPlayerMints[prevPlayerMints.length - 1].id + 1 : 1,
        playerAddress: "",
        numberOfRewards: 1,
        numberOfLootboxes: 1,
      },
    ])
    setPlayerMintsErrors([])
  }, [])

  const mintAndTransfer = useCallback(async () => {
    if (!playerMints.length) return
    setPlayerMintsErrors([])

    try {
      // validate player addresses
      const newPlayerAddressErrors = playerMints.map((playerMint) =>
        ethers.utils.isAddress(playerMint.playerAddress) ? "" : "Player address is not valid"
      )
      setPlayerMintsErrors([...newPlayerAddressErrors])

      const arePlayerAddressesValid = !newPlayerAddressErrors.find((pE) => !!pE)
      if (!arePlayerAddressesValid) return

      setIsMintsLoading(true)
      await mintAndTransferToPlayers(
        playerMints.map((p) => p.playerAddress),
        playerMints.map((p) => p.numberOfRewards),
        playerMints.map((p) => p.numberOfLootboxes)
      )

      setIsMintsLoading(false)
      setPlayerMints([{ id: 1, playerAddress: "", numberOfRewards: 1, numberOfLootboxes: 1 }])
      enqueueSnackbar(t`Lootboxes have been minted for players`, { variant: "success" })
    } catch (e) {
      setIsMintsLoading(false)
      enqueueSnackbar((e as Error).message, { variant: "error" })
    }
  }, [mintAndTransferToPlayers, playerMints, enqueueSnackbar])

  return {
    playerMints,
    isMintsLoading,
    playerMintsErrors,
    updatePlayerAddress,
    updateNumberOfRewards,
    updateNumberOfLootboxes,
    removeMintAndTransferData,
    addMintAndTransferData,
    mintAndTransfer,
  }
}
