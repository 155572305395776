import { t, Trans } from "@lingui/macro"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Typography,
  TextField,
  Modal,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Chip,
} from "@mui/material"
import { useFormik } from "formik"
import { useSnackbar } from "notistack"
import React from "react"
import { object, string, array } from "yup"

import type { IProject } from "../../../contexts/ProjectContext"
import { useProjectApi } from "../../../contexts/ProjectContext"

interface ICreateProjectModalProps {
  onClose: () => void
  isOpen: boolean
  modalMode?: "create" | "edit"
  project?: IProject
  onNewProject?: (project: IProject) => void
}

const projectStageOptions = ["Building", "Production"]

const targetPlatformOptions = ["Mobile", "Desktop", "Browser", "Console", "Other"]

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -40%)",
  width: 460,
  border: "1px solid #262626",
  boxShadow: 24,
  bgcolor: "#121212",
  borderRadius: "4px",
  padding: 4,
  height: "fit-content",
  overflow: "auto",
  maxHeight: "100vh",
}

const CreateProjectModal: React.FC<ICreateProjectModalProps> = ({
  onClose,
  isOpen,
  modalMode,
  project,
  onNewProject,
}: ICreateProjectModalProps) => {
  const { createProject, updateProject } = useProjectApi()
  const { enqueueSnackbar } = useSnackbar()

  const projectSchema = object().shape({
    name: string()
      .trim()
      .min(1, t`Please enter a project name`)
      .max(100, t`Project name is too long`)
      .required(t`Project name is required`),
    projectURL: string()
      .trim()
      .matches(
        /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
        t`Please enter a valid URL`
      ),
    projectStage: string()
      .trim()
      .min(1, "Project stage is required")
      .required(t`Project stage is required`),
    targetPlatforms: array()
      .of(string().trim())
      .min(1, t`Target platforms are required`)
      .required(t`Target platforms are required`),
  })

  const projectFormik = useFormik<{
    name: string
    projectURL: string
    projectStage: string
    targetPlatforms: string[]
  }>({
    initialValues: {
      name: project?.name || "",
      projectStage: project?.projectStage || "",
      projectURL: project?.projectURL || "",
      targetPlatforms: project?.targetPlatforms || [],
    },
    validationSchema: projectSchema,
    validateOnChange: false,
    enableReinitialize: false,
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true)
        enqueueSnackbar(modalMode === "edit" ? t`Updating project` : t`Creating project`, {
          variant: "info",
        })
        if (modalMode === "create") {
          // create project
          const newProject = await createProject({
            name: values.name.trim(),
            projectStage: values.projectStage.trim(),
            projectURL: values.projectURL.trim(),
            targetPlatforms: values.targetPlatforms,
          })
          if (newProject) {
            onNewProject && onNewProject(newProject)
            enqueueSnackbar(t`Project created`, { variant: "success" })
          }
        } else if (modalMode === "edit" && project) {
          // edit project
          const completed = await updateProject({
            ...project,
            projectId: project.projectId,
            name: values.name.trim(),
            projectStage: values.projectStage.trim(),
            projectURL: values.projectURL.trim(),
            targetPlatforms: values.targetPlatforms,
          })
          if (completed) {
            enqueueSnackbar(t`Project updated`, { variant: "success" })
          }
        }
        helpers.setSubmitting(false)
        helpers.resetForm()
        onClose()
      } catch (error: any) {
        console.error(error)
        helpers.setSubmitting(false)
        enqueueSnackbar(
          error.response ||
            error.message ||
            (modalMode === "edit" ? t`Failed to update project` : t`Failed to create project`),
          {
            variant: "error",
          }
        )
      }
    },
  })

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose()
        projectFormik.resetForm()
      }}
    >
      <Box sx={style}>
        <Typography variant="h5" mb={2} align="center">
          {modalMode === "edit" ? t`Edit Project` : t`New Project`}
        </Typography>
        <Typography variant="body1" mb={4} align="center">
          {modalMode === "edit"
            ? t`Fill out the information below to update project.`
            : t`Fill out the information below to generate a project ID and register your game in Web3.Unity.`}
        </Typography>
        <form onSubmit={projectFormik.handleSubmit} noValidate>
          <TextField
            sx={{ mb: 3 }}
            fullWidth
            id="name"
            name="name"
            data-cy="input-project-modal-name"
            label={t`Project Name`}
            required
            placeholder={t`Please enter a project name`}
            size="small"
            autoFocus
            value={projectFormik.values.name}
            onChange={projectFormik.handleChange}
            error={projectFormik.touched.name && Boolean(projectFormik.errors.name)}
            helperText={projectFormik.touched.name && projectFormik.errors.name}
          />

          <TextField
            sx={{ mb: 3 }}
            fullWidth
            id="projectURL"
            name="projectURL"
            data-cy="input-project-modal-url"
            label={t`Project URL`}
            placeholder={t`Enter your project URL`}
            size="small"
            value={projectFormik.values.projectURL}
            onChange={projectFormik.handleChange}
            error={projectFormik.touched.projectURL && Boolean(projectFormik.errors.projectURL)}
            helperText={projectFormik.touched.projectURL && projectFormik.errors.projectURL}
          />

          <FormControl
            fullWidth
            sx={{ mb: 2 }}
            size="small"
            error={projectFormik.touched.projectStage && Boolean(projectFormik.errors.projectStage)}
            data-cy="form-project-modal-stage"
          >
            <InputLabel id="projectStage" required>
              <Trans>Project Stage</Trans>
            </InputLabel>
            <Select
              id="projectStage"
              data-cy="dropdown-project-modal-stage"
              value={projectFormik.values.projectStage}
              label={t`Project Stage`}
              placeholder={t`Project Stage`}
              onChange={(e) => {
                projectFormik.setFieldValue("projectStage", e.target.value || "")
              }}
              error={
                projectFormik.touched.projectStage && Boolean(projectFormik.errors.projectStage)
              }
            >
              {projectStageOptions.map((w) => (
                <MenuItem
                  key={w}
                  value={w}
                  data-cy={`dropdown-project-stage-option-${w.toLowerCase()}`}
                >
                  {w}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error>{projectFormik.errors.projectStage}</FormHelperText>
          </FormControl>

          {/* <FormControl fullWidth
          sx={{ mb: 3, backgroundColor: "white" }}
          size="small"
          error={projectFormik.touched.targetPlatforms 
            && Boolean(projectFormik.errors.targetPlatforms)
          }
          data-cy="form-project-modal-target-platforms"
        >
          <InputLabel id="targetPlatforms"><Trans>Target Platforms *</Trans></InputLabel>
          <Select
            id="targetPlatforms"
            data-cy="dropdown-project-modal-target-platforms"
            value={projectFormik.values.targetPlatforms}
            label={t`Target Platforms *`}
            placeholder={t`Target Platforms`}
            multiple
            onChange={(e) => {
              projectFormik.setFieldValue("targetPlatforms", e.target.value || "")
            }}
            error={projectFormik.touched.targetPlatforms 
              && Boolean(projectFormik.errors.targetPlatforms)
            }
          >
            {targetPlatformOptions.map((w) => <MenuItem
              key={w}
              value={w}
              data-cy={`dropdown-project-target-platforms-option-${w.toLowerCase()}`}
            >
              {w}
            </MenuItem>)}
          </Select>
          <FormHelperText error>{projectFormik.errors.targetPlatforms}</FormHelperText>
        </FormControl> */}

          <FormControl
            fullWidth
            sx={{ mb: 3 }}
            size="small"
            error={
              projectFormik.touched.targetPlatforms && Boolean(projectFormik.errors.targetPlatforms)
            }
            data-cy="form-project-modal-target-platforms"
          >
            <Typography sx={{ mb: 1 }} color="GrayText" variant="body2">
              <Trans>
                Target Platforms <span style={{ color: "#EF4444" }}>*</span> (Select all that apply)
              </Trans>
            </Typography>
            <Box display="grid" gridTemplateColumns={"repeat(5, 1fr)"} gap={1}>
              {targetPlatformOptions.map((targetPlatform) => (
                <Chip
                  key={targetPlatform}
                  label={targetPlatform}
                  variant="outlined"
                  clickable={true}
                  color={
                    projectFormik.values.targetPlatforms.includes(targetPlatform)
                      ? "primary"
                      : "default"
                  }
                  onClick={() => {
                    if (projectFormik.values.targetPlatforms.includes(targetPlatform)) {
                      projectFormik.setFieldValue(
                        "targetPlatforms",
                        projectFormik.values.targetPlatforms.filter((pt) => pt !== targetPlatform)
                      )
                    } else {
                      projectFormik.setFieldValue("targetPlatforms", [
                        ...projectFormik.values.targetPlatforms,
                        targetPlatform,
                      ])
                    }
                  }}
                  data-cy={`dropdown-project-target-platforms-option-${targetPlatform.toLowerCase()}`}
                />
              ))}
            </Box>
            <FormHelperText error>{projectFormik.errors.targetPlatforms}</FormHelperText>
          </FormControl>

          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              data-cy="button-project-modal-cancel"
              variant="text"
              sx={{
                color: "#F6F6F6",
                backgroundColor: "#595959",
                width: 100,
                mr: 1,
                ":hover": {
                  backgroundColor: "#595959",
                },
              }}
              size="small"
              onClick={() => {
                onClose()
                projectFormik.resetForm()
              }}
            >
              <Trans>Cancel</Trans>
            </Button>
            <LoadingButton
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              loading={projectFormik.isSubmitting}
              sx={{ ml: 1, width: 140 }}
              data-cy={
                modalMode === "create"
                  ? "button-project-modal-create"
                  : "button-project-modal-update"
              }
            >
              {modalMode === "create" ? (
                <Trans>Create Project</Trans>
              ) : (
                <Trans>Update Project</Trans>
              )}
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </Modal>
  )
}

export default CreateProjectModal
