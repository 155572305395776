import type { BigNumber } from "ethers"

export const TOKEN_TYPE_KEYS = {
  ERC20: [0, 1],
  ERC721: [2],
  ERC1155: [3],
  ERC1155NFT: [4],
} as const

export type TokenType = (typeof TOKEN_TYPE_KEYS)[keyof typeof TOKEN_TYPE_KEYS][number]

export const TOKEN_TYPES = Object.fromEntries(
  Object.entries(TOKEN_TYPE_KEYS).flatMap(([key, values]) => values.map((value) => [value, key]))
) as Readonly<Record<TokenType, string>>

export type LootboxInventoryResult = {
  rewardToken: string
  rewardType: TokenType
  amountPerUnit: BigNumber
  balance: BigNumber
  extra: {
    amountPerUnit: BigNumber
    balance: BigNumber
    id?: BigNumber
  }[]
}

export type LootboxInventoryResponse = {
  result: LootboxInventoryResult[]
  leftoversResult?: LootboxInventoryResult[]
}

export type LootboxExtraInventory = {
  amountPerUnit: number
  balance: number
  id?: number
}

export type LootboxInventory = {
  tokenAddress: string
  rewardType: TokenType
  amountPerUnit: number
  balance: number
  extra?: LootboxExtraInventory[]
  decimals?: number
}

export type LootboxData = {
  address: string
  name?: string
  image?: string
  description?: string
}
