import { Box } from "@mui/material"
import { useState } from "react"

import DepositorAddresses from "./DepositorAddresses"
import RewardUnits from "./ManageRewards/RewardUnits"
import MintAndTransfer from "./MintAndTransfer"
import Review from "./Review"
import SendLoot from "./SendLoot"
import ManageLootboxSidebar from "./Sidebar"
import LootboxTokenAddresses from "./TokenAddresses"
import type { TManageLootboxTab } from "./types"

const ManageLootbox = (): JSX.Element => {
  const [tab, setTab] = useState<TManageLootboxTab>("tokenAddresses")

  return (
    <Box display="flex" mt={3}>
      <ManageLootboxSidebar tab={tab} setTab={setTab} />
      <Box width="100%">
        {tab === "tokenAddresses" ? (
          <LootboxTokenAddresses />
        ) : tab === "depositorAccess" ? (
          <DepositorAddresses />
        ) : tab === "sendLoot" ? (
          <SendLoot />
        ) : tab === "rewards" ? (
          <RewardUnits />
        ) : tab === "mint" ? (
          <MintAndTransfer />
        ) : (
          <Review />
        )}
      </Box>
    </Box>
  )
}

export default ManageLootbox
