import { Trans } from "@lingui/macro"
import { Box, Typography } from "@mui/material"
import { ethers } from "ethers"
import { useState, useEffect } from "react"

import { useWeb3Connection } from "../../../../../contexts/Web3ConnectionContext"
import GenericERC1155 from "../../../../../contracts/Generic/GenericERC1155.json"
import GenericERC721 from "../../../../../contracts/Generic/GenericERC721.json"
import { getIPFSUri } from "../../../../../utils/Helpers"
import type { TokenType } from "../../../../../types/lootbox"
import { TOKEN_TYPES } from "../../../../../types/lootbox"

interface IProps {
  address: string
  tokenId: number
  tokenType: TokenType
  amount?: string
}

const RewardCard: React.FC<IProps> = ({ address, tokenId, tokenType, amount }) => {
  const { signer } = useWeb3Connection()
  const [nftData, setNftData] = useState<{ name: string; image: string; description: string }>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function fetchNFTData(): Promise<void> {
      try {
        const contract = new ethers.Contract(
          address,
          tokenType === 2 ? GenericERC721 : GenericERC1155,
          signer
        )
        const tokenURI = await (tokenType === 2
          ? contract.tokenURI(tokenId)
          : contract.uri(tokenId))

        if (tokenURI) {
          const data = await (await fetch(tokenURI.replace("{id}", tokenId))).json()
          setNftData(data)
        }
        setIsLoading(false)
      } catch (error) {
        console.error("Error fetching NFT data:", error)
        setIsLoading(false)
      }
    }

    if (address && tokenId !== undefined && signer) {
      fetchNFTData()
    }
  }, [address, tokenId, tokenType, signer])

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      sx={{
        width: "100%",
        height: 260,
        border: "1px solid #BFBFBF",
        borderRadius: "10px",
        maxWidth: 200,
      }}
    >
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height="100%"
        >
          <Typography>
            <Trans>Loading...</Trans>
          </Typography>
        </Box>
      ) : !nftData ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height="100%"
        >
          <Typography fontSize={22} align="center">
            <Trans>{TOKEN_TYPES[tokenType]} token</Trans>
          </Typography>
          <Typography align="center">
            <Trans>Token # {tokenId}</Trans>
          </Typography>
        </Box>
      ) : (
        <>
          <img
            src={getIPFSUri(nftData.image)}
            alt={nftData.name}
            style={{ maxWidth: "inherit", borderRadius: "10px", height: "auto" }}
          />
          <Box
            flex={1}
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography>{nftData.name}</Typography>
            {tokenType === 3 && amount ? (
              <Typography fontSize={16}>Quantity: {amount}</Typography>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  )
}

export default RewardCard
