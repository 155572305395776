import { Box, styled, Typography } from "@mui/material"
import { SoftButton } from "../../../../elements/SoftButton"

export const ManageHeaderContainer = styled(Box)`
  padding: 24px 32px;
  width: 100%;
`

export const HeadingTextContainer = styled(Box)`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
`

export const HeadingText = styled(Typography)`
  color: #f5f5f5;
  font-size: 24px;
`

export const SubtitleText = styled(Typography)`
  margin-top: 8px;
  color: #bfbfbf;
`

export const ActionButton = styled(SoftButton)(() => ({
  color: "#dff7c7",
}))
