import { Trans, t } from "@lingui/macro"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { Box, TextField, Typography } from "@mui/material"

import { useERC20Distributions } from "./hooks/useERC20Distributions"
import CustomModal from "../../../../elements/CustomModal"
import {
  ActionButton,
  HeadingSection,
  StepsSection,
  StepText,
  AllStepsContainer,
  StepContainer,
  StepBody,
  StepFooter,
  BundleContainer,
  CrossButton,
  AddBundleButton,
  Footer,
  CancelButton,
} from "./styles/RewardUnits.styles"

interface IVarERC20ModalProps {
  open: boolean
  onClose: () => void
}

const VarERC20Modal: React.FC<IVarERC20ModalProps> = ({ open, onClose }) => {
  const {
    step,
    tokenAddress,
    tokenBundles,
    setTokenAddress,
    isTxLoading,
    addBundle,
    removeBundle,
    updateTokenBundle,
    updateTokenNumber,
    whitelistToken,
    approveToken,
    activateVariableDistributions,
    clearStates,
    isTokenAddressValid,
  } = useERC20Distributions()

  const onCloseModal = (): void => {
    clearStates()
    onClose()
  }

  const activateERC20 = (): void => {
    activateVariableDistributions().then((success) => {
      if (success) onCloseModal()
    })
  }

  return (
    <CustomModal open={open} onClose={onCloseModal} width="560px">
      <HeadingSection>
        <Typography variant="h5">
          <Trans>ERC20 Variable Distributions</Trans>
        </Typography>
        <Typography variant="body2">
          <Trans>
            By activating variable distributions, you can create multiple bundles of the ERC20
            token, with each bundle containing a different number of tokens.
          </Trans>
        </Typography>
      </HeadingSection>

      <StepsSection>
        <StepText active={String(step === 1)}>1. Input ERC20 address</StepText>
        <ArrowForwardIosIcon fontSize="inherit" />
        <StepText active={String(step === 2)}>2. Approve Spending</StepText>
        <ArrowForwardIosIcon fontSize="inherit" />
        <StepText active={String(step === 3)}>3. Bundles and Tokens</StepText>
      </StepsSection>
      {/* step 1: Input ERC20 address */}
      <AllStepsContainer>
        <StepContainer active={String(step === 1)}>
          <Typography variant="body2">Step 1: Input ERC20 Address</Typography>
          {step === 1 && (
            <Box>
              <StepBody>
                <TextField
                  fullWidth
                  label="ERC20 Address"
                  size="small"
                  value={tokenAddress}
                  onChange={(e) => {
                    setTokenAddress(e.target.value)
                  }}
                />
              </StepBody>
              <StepFooter>
                <ActionButton
                  disabled={!isTokenAddressValid}
                  size="small"
                  onClick={whitelistToken}
                  loading={isTxLoading}
                >
                  Whitelist ERC20 address
                </ActionButton>
              </StepFooter>
            </Box>
          )}
        </StepContainer>
        <StepContainer active={String(step === 2)}>
          <Typography variant="body2">Step 2: Approve Spending</Typography>
          {step === 2 && (
            <Box>
              <StepBody>
                <Typography variant="body2">
                  <Trans>
                    Approve LootBox to create bundles and spend ERC20 tokens when distributing
                    rewards
                  </Trans>
                </Typography>
              </StepBody>
              <StepFooter>
                <ActionButton size="small" onClick={approveToken} loading={isTxLoading}>
                  Approve LootBox spending
                </ActionButton>
              </StepFooter>
            </Box>
          )}
        </StepContainer>
        <StepContainer active={String(step === 3)}>
          <Typography variant="body2">Step 3: Input the number of bundles and tokens</Typography>
          {step === 3 && (
            <Box>
              <StepBody>
                {tokenBundles.map((tokenBundle, index) => (
                  <BundleContainer key={index}>
                    <TextField
                      fullWidth
                      size="small"
                      label={t`# of tokens`}
                      value={tokenBundle.numberOfTokens}
                      onChange={(e) => updateTokenNumber(e, index)}
                      placeholder={t`Enter # of tokens`}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      label={t`# of bundles`}
                      value={tokenBundle.numberOfBundles}
                      onChange={(e) => updateTokenBundle(e, index)}
                      placeholder={t`Enter # of bundles`}
                    />
                    <CrossButton onClick={() => removeBundle(index)} />
                  </BundleContainer>
                ))}
                <AddBundleButton size="small" onClick={() => addBundle()}>
                  <Trans>+ add another bundle</Trans>
                </AddBundleButton>
              </StepBody>
              <StepFooter>
                <ActionButton size="small" onClick={activateERC20} loading={isTxLoading}>
                  <Trans>Activate variable distributions</Trans>
                </ActionButton>
              </StepFooter>
            </Box>
          )}
        </StepContainer>
      </AllStepsContainer>
      <Footer>
        <CancelButton onClick={onCloseModal} variant="text">
          <Trans>Cancel</Trans>
        </CancelButton>
      </Footer>
    </CustomModal>
  )
}

export default VarERC20Modal
